import {
  IMAGE_LOADER,
  ImageLoaderConfig,
  provideImageKitLoader
} from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  enableProdMode,
  isDevMode
} from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  AppCheckToken,
  CustomProvider,
  ReCaptchaV3Provider,
  initializeAppCheck,
  provideAppCheck
} from '@angular/fire/app-check';
import {
  browserLocalPersistence,
  connectAuthEmulator,
  indexedDBLocalPersistence,
  initializeAuth,
  provideAuth
} from '@angular/fire/auth';
import {
  connectFirestoreEmulator,
  initializeFirestore,
  persistentLocalCache,
  provideFirestore
} from '@angular/fire/firestore';
import {
  connectFunctionsEmulator,
  getFunctions,
  provideFunctions
} from '@angular/fire/functions';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import {
  connectStorageEmulator,
  getStorage,
  provideStorage
} from '@angular/fire/storage';
import { bootstrapApplication } from '@angular/platform-browser';
import {
  RouteReuseStrategy,
  Router,
  TitleStrategy,
  provideRouter
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { FirebaseAppCheck } from '@capacitor-firebase/app-check';
import { Capacitor } from '@capacitor/core';
import {
  IonicRouteStrategy,
  provideIonicAngular
} from '@ionic/angular/standalone';
import { defineCustomElements as defineIonicPWAElements } from '@ionic/pwa-elements/loader';
import {
  TraceService,
  browserProfilingIntegration,
  browserTracingIntegration,
  createErrorHandler,
  init as initSentry,
  replayIntegration
} from '@sentry/angular';
import { getFirestore } from 'firebase/firestore';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { defineCustomElements as defineStripeCustomElements } from 'stripe-pwa-elements/loader';
import { register as registerSwiperCustomElements } from 'swiper/element/bundle';
import { AppComponent } from './app/app.component';
import { APP_ROUTES, BuildshareTitleStrategy } from './app/app.routes';
import { environment } from './environments/environment';
import { emulatorHost, emulatorPorts } from './environments/shared';
import { version } from './version';

// Initialise Sentry & Clarity in production environments
if (environment.production) {
  enableProdMode();

  initSentry({
    dsn: environment.sentry.dsn,
    release: version.name,
    dist: version.code,
    integrations: [
      browserTracingIntegration(),
      browserProfilingIntegration(),
      replayIntegration()
    ],
    environment: environment.name,
    // Performance Monitoring
    tracesSampleRate: environment.sentry.tracesSampleRate,
    // Session Replay
    replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate,
    replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate,
    // Distributed Tracing
    tracePropagationTargets: [
      'localhost',
      environment.baseUrl,
      /^\//,
      /(^|\.)run\.app$/
    ]
  });
}

// Initialise Angular application providers
const providers = [
  {
    provide: ErrorHandler,
    useValue: createErrorHandler()
  },
  {
    provide: TraceService,
    deps: [Router]
  },
  {
    provide: APP_INITIALIZER,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    useFactory: () => () => {},
    deps: [TraceService],
    multi: true
  },
  { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  {
    provide: TitleStrategy,
    useClass: BuildshareTitleStrategy
  },
  { provide: LOCALE_ID, useValue: 'en-GB' },
  provideCharts(withDefaultRegisterables()),
  provideServiceWorker('ngsw-worker.js', {
    enabled: !isDevMode() && !Capacitor.isNativePlatform(),
    registrationStrategy: 'registerWhenStable:30000'
  }),
  provideHttpClient(withInterceptorsFromDi()),
  provideIonicAngular({ mode: 'ios' }),
  provideRouter(APP_ROUTES),
  // Initialise Firebase services
  provideFirebaseApp(() => initializeApp(environment.firebase.config)),
  provideAppCheck(() => {
    let provider: CustomProvider | ReCaptchaV3Provider;
    if (Capacitor.isNativePlatform()) {
      globalThis.FIREBASE_APPCHECK_DEBUG_TOKEN = false;
      provider = new CustomProvider({
        // Pull token from native layer into web layer
        getToken: () => FirebaseAppCheck.getToken() as Promise<AppCheckToken>
      });
    } else {
      provider = new ReCaptchaV3Provider(environment.firebase.recaptchaSiteKey);
    }
    // Initialise AppCheck on web layer
    return initializeAppCheck(getApp(), { provider });
  }),
  providePerformance(() => getPerformance()),
  provideAuth(() => {
    let persistence = Capacitor.isNativePlatform()
      ? indexedDBLocalPersistence
      : browserLocalPersistence;
    const auth = initializeAuth(getApp(), { persistence });
    if (environment.firebase.useEmulators.auth) {
      connectAuthEmulator(
        auth,
        `http://${emulatorHost}:${emulatorPorts.auth}`,
        {
          disableWarnings: true
        }
      );
    }
    return auth;
  }),
  provideFirestore(() => {
    const firestore = Capacitor.isNativePlatform()
      ? initializeFirestore(getApp(), {
          // Explicitly enable caching for offline persistence (default is off for web)
          localCache: persistentLocalCache()
        })
      : getFirestore();
    if (environment.firebase.useEmulators.firestore) {
      if (!firestore['_initialized']) {
        connectFirestoreEmulator(
          firestore,
          emulatorHost,
          emulatorPorts.firestore
        );
      }
    }
    return firestore;
  }),
  provideFunctions(() => {
    const functions = getFunctions(
      getApp(),
      environment.firebase.functions.region
    );
    if (environment.firebase.useEmulators.functions) {
      connectFunctionsEmulator(
        functions,
        emulatorHost,
        emulatorPorts.functions
      );
    }
    return functions;
  }),
  provideStorage(() => {
    const storage = getStorage();
    if (environment.firebase.useEmulators.storage) {
      connectStorageEmulator(storage, emulatorHost, emulatorPorts.storage);
    }
    return storage;
  })
];

// Initialise ImageKit loader if defined
if (environment['imageKit']) {
  let imageLoaderProvider;
  // If running on native or localhost, /assets are stored locally so don't use CDN for them
  if (Capacitor.isNativePlatform() || isDevMode()) {
    imageLoaderProvider = {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        const trimmedSrc = config.src?.replace(/^[/.]+/, '');
        // Get assets locally
        if (trimmedSrc.startsWith('assets')) {
          return config.src;
          // If using storage emulator, use emulator URL
        } else if (environment.firebase.useEmulators.storage) {
          return `http://${emulatorHost}:${emulatorPorts.storage}/v0/b/${environment.firebase.config.storageBucket}/o/${trimmedSrc}`;
        } else {
          // But use CDN for everything else
          // TODO: Get rid of this if we can directly access the createImagekitUrl method from angular/common
          const { src, width } = config;
          const path = environment['imageKit'].baseUrl;
          let urlSegments: string[];

          if (width) {
            const params = `tr:w-${width}`;
            urlSegments = [path, params, src];
          } else {
            urlSegments = [path, src];
          }

          return urlSegments.join('/');
        }
      }
    };
  } else {
    // If on browser, always use CDN
    imageLoaderProvider = provideImageKitLoader(
      environment['imageKit'].baseUrl
    );
  }
  providers.push(imageLoaderProvider);
}

function bootstrap() {
  bootstrapApplication(AppComponent, { providers: providers })
    .then(() => defineIonicPWAElements(window))
    .then(() => defineStripeCustomElements(window))
    .then(() => registerSwiperCustomElements())
    .catch((err) => console.log(err));
}

// Initialise Firebase App Check for Android before bootstrapping (we initialise in ios in AppDelegate)
// Once https://github.com/capawesome-team/capacitor-firebase/issues/453 is resolved, we can change to isNativePlatform()
if (Capacitor.getPlatform() === 'android') {
  FirebaseAppCheck.initialize({
    debug: isDevMode()
  }).then(() => bootstrap());
} else {
  bootstrap();
}
