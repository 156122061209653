import { Injectable, Signal, computed, signal } from '@angular/core';
import {
  AddConfigUpdateListenerOptionsCallbackEvent,
  FirebaseRemoteConfig
} from '@capacitor-firebase/remote-config';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  updatedKeys = signal([]);

  constructor() {
    // Fetch Remote Config and activate values
    FirebaseRemoteConfig.fetchAndActivate();

    // Add a real-time listener to track updated keys and notify downstream signals
    if (Capacitor.isNativePlatform()) {
      FirebaseRemoteConfig.addConfigUpdateListener(
        async (
          updateEvent: AddConfigUpdateListenerOptionsCallbackEvent,
          error
        ) => {
          if (error) {
            console.error(error);
            return;
          }

          // Activate new values immediately
          await FirebaseRemoteConfig.activate();

          // Update the signal with the latest updated keys
          this.updatedKeys.set(updateEvent.updatedKeys);
        }
      );
    }
  }

  async getValue<T>(
    key: string,
    type: 'string' | 'number' | 'boolean'
  ): Promise<T> {
    let result;
    switch (type) {
      case 'string':
        result = await FirebaseRemoteConfig.getString({
          key
        });
        break;
      case 'number':
        result = await FirebaseRemoteConfig.getNumber({
          key
        });
        break;
      case 'boolean':
        result = await FirebaseRemoteConfig.getBoolean({
          key
        });
        break;
    }
    return result.value;
  }

  async getValueSignal<T>(
    key: string,
    type: 'string' | 'number' | 'boolean'
  ): Promise<Signal<Promise<T>>> {
    // Get the current value (if any)
    let value = await this.getValue<T>(key, type);

    // Whenever the updatedKeys signal changes, recompute the value
    return computed(async () => {
      if (this.updatedKeys().includes(key)) {
        value = await this.getValue<T>(key, type);
      }
      return value;
    });
  }
}
