import { Component, Injector, NgZone, effect, inject } from '@angular/core';
import { Performance, trace } from '@angular/fire/performance';
import {
  GuardsCheckEnd,
  NavigationCancel,
  NavigationEnd,
  ResolveEnd,
  Router
} from '@angular/router';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import {
  IonApp,
  IonModal,
  IonRouterOutlet,
  ModalController,
  Platform,
  ToastController
} from '@ionic/angular/standalone';
import { filter, first } from 'rxjs';
import { environment } from '../environments/environment';
import { version } from './../version';
import { LoadingComponent } from './core/components/loading/loading.component';
import { UpdateAppModal } from './core/modals/update-app.modal';
import { AnalyticsService } from './core/services/analytics/analytics.service';
import { ConfigService } from './core/services/config/config.service';
import { NotificationService } from './core/services/notification/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonModal, LoadingComponent, IonApp, IonRouterOutlet]
})
export class AppComponent {
  loading = true;
  updateModal: HTMLIonModalElement;

  constructor(
    private platform: Platform,
    private router: Router,
    private zone: NgZone,
    private analytics: AnalyticsService,
    private performance: Performance,
    private notifications: NotificationService,
    private toasts: ToastController,
    private injector: Injector,
    private modals: ModalController
  ) {
    this.initialiseApp();
    if (Capacitor.isNativePlatform()) {
      const config = inject(ConfigService);
      this.monitorMinAppVersion(config);
    } else {
      this.checkCookieConsent();
    }
  }

  initialiseApp() {
    const startupTrace = trace(this.performance, 'angularStartup');
    startupTrace.start();
    SplashScreen.hide();
    this.platform.ready().then(() => {
      // Initiate page tracking ==========================================
      this.analytics.trackPageViews();
      // Listen for notifications ========================================
      this.notifications.addPushListeners();
      // Initialise Facebook SDK ==========================================
      FacebookLogin.initialize({ appId: environment.facebook.appId });

      // Add listener for handling deep links ============================
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        // Close internal browser if stripe redirect custom scheme
        if (event.url === environment.stripe.redirectScheme) {
          Browser.close();
        } else {
          // Handle universal/deep links
          this.zone.run(() => {
            const slug = event.url.replace(environment.universalLinkUrl, '');
            if (slug) {
              this.router.navigateByUrl(slug);
            }
          });
        }
      });

      // Hide splash screen when route is resolved =======================
      this.router.events
        .pipe(
          filter(
            (event) =>
              event instanceof NavigationEnd ||
              event instanceof GuardsCheckEnd ||
              event instanceof NavigationCancel ||
              event instanceof ResolveEnd
          ),
          first()
        )
        .subscribe(() => {
          this.loading = false;
          startupTrace.stop();
        });
    });
  }

  checkCookieConsent() {
    let consent;
    try {
      consent = localStorage.getItem('cookieConsent');
    } catch (error) {
      consent = false;
    }
    if (!consent) {
      this.toasts
        .create({
          message: 'By using this site, you consent to our cookie policy.',
          translucent: true,
          buttons: [
            {
              text: 'View',
              role: 'info',
              handler: () => this.router.navigate(['/cookie-policy'])
            },
            {
              text: 'OK',
              role: 'cancel',
              handler: () => {
                try {
                  localStorage.setItem('cookieConsent', 'true');
                } catch (err) {
                  console.error(err);
                }
              }
            }
          ]
        })
        .then((toast) => toast.present());
    }
  }

  async monitorMinAppVersion(config: ConfigService) {
    const minVersion = await config.getValueSignal<number>(
      'minVersionCode',
      'number'
    );

    // Check if the current version is less than the minimum required
    effect(
      async () => {
        const minimum = await minVersion();
        if (+version.code < minimum) {
          if (!this.updateModal) {
            this.updateModal = await this.modals.create({
              component: UpdateAppModal
            });
            await this.updateModal.present();
          }
        } else {
          if (this.updateModal) {
            await this.updateModal.dismiss();
            this.updateModal = undefined;
          }
        }
      },
      { injector: this.injector }
    );
  }
}
